import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  TextField,
  Button,
} from '@material-ui/core';

const primaryColor = '#fb314a';

const styles = theme => ({
  fileField: {
    color: `${primaryColor}`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderColor: `${primaryColor} !important`,
    width: "calc(100% - 18px)"
  },
});

class FileInput extends Component {
  bothInputProps = (value, error) => ({
    InputLabelProps: {
      classes: {
        root: (value && !error) ? 'cssLabel' : (((!value && error) || error) ? 'cssLabelError' : 'cssLabelSuccess'),
      },
      shrink: true,
    },
    InputProps: {
      classes: {
        notchedOutline: (value && !error) ? 'cssInput' : (((!value && error) || error) ? 'cssInputError' : 'cssInputSuccess'),
      },
    },
  });

  render() {
    const {
      name,
      value,
      errors,
      onChange,
      required = true,
      helperText = '',
      placeholder = '',
      classes,
    } = this.props;

    return (
      <>
        <TextField
          name={name}
          style={{ display: "none" }}
          id={`contained-button-${name}`}
          type="file"
          required={required}
          onChange={onChange}
        />
        <TextField
          className={`field-responsive ${classes.fileField}`}
          style={{width: "100%"}}
          type="text"
          disabled={true}
          margin="normal"
          required={required}
          variant="outlined"
          value={value ? (value.name || value) : placeholder}
          {...this.bothInputProps(value, errors)}
          helperText={helperText}
        />
        <label htmlFor={`contained-button-${name}`}>
          <Button variant="contained" component="span" className="button-browse-file">
            Examinar
          </Button>
        </label>
      </>
    );
  }
}

FileInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FileInput);
