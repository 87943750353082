import React, { Component } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { MenuItem, TextField } from '@material-ui/core';

class SelectSimpleOutlined extends Component {
  bothInputProps = (value, error) => ({
    InputLabelProps: {
      classes: {
        root: (value && !error) ? 'cssLabel' : (((!value && error) || error) ? 'cssLabelError' : 'cssLabelSuccess'),
      },
      shrink: true,
    },
    InputProps: {
      classes: {
        notchedOutline: (value && !error) ? 'cssInput' : (((!value && error) || error) ? 'cssInputError' : 'cssInputSuccess'),
      },
    },
  });

  render() {
    const {
      name,
      value,
      errors,
      error = true,
      onChange,
      label = '',
      listItems = [],
      simpleList = null,
      otherText= '',
      helperText = '',
      required = true,
      placeholder = '',
      variant = 'outlined',
      addClass = '',
      labelClass = '',
      showOther = false,
      FormHelperTextProps = {},
      disabled = false,
      otherOption = false,
    } = this.props;

    const labelInput = label ? <span className={labelClass}>{label}</span> : label;

    return (
      <TextField
        select={true}
        label={labelInput}
        name={name}
        error={error}
        variant={variant}
        placeholder={placeholder}
        className={addClass}
        value={value}
        onChange={onChange}
        {...this.bothInputProps(value, errors)}
        SelectProps={{
          IconComponent: KeyboardArrowDownIcon,
        }}
        helperText={helperText}
        margin="normal"
        required={required}
        FormHelperTextProps={FormHelperTextProps}
        disabled={disabled}
      >
        <MenuItem value="default" hidden disabled>{placeholder}</MenuItem>
        {
          simpleList
          ? simpleList.map((i, index) => <MenuItem key={index} value={i}>{i} {otherText}</MenuItem>)
          : listItems.map((i, index) => <MenuItem key={index} value={i.value} disabled={i.disabled}>{i.name}</MenuItem>)
        }
        {showOther && <MenuItem value="OTRO">Otro</MenuItem>}
        {otherOption && <MenuItem value={otherOption?.value}><b className="text-purple-500">{otherOption?.name}</b></MenuItem>}
      </TextField>
    );
  }
}

export default SelectSimpleOutlined;
