import React, {useState, useEffect} from 'react';
import {Menu, MenuItem, Divider} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled from 'styled-components';

import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { bindActionCreators } from 'redux';

const StyledMenu = styled(({ className, ...props }) => (
  <Menu {...props} classes={{ paper: className }} />
))`
  box-shadow: 5px 5px 10px -8px rgba(0,0,0,0.20) !important;
  border: 1px solid #e6ebf1;
`;

const SelectCurrency = (props) => {
  const {
    actions,
    currency,
    currencies,
    hideFlag = false,
    setData = null,
  } = props;

  const allCurrencies = typeof currencies === 'string' ? JSON.parse(currencies) : currencies;
  const current_currency = typeof currency === 'string' ? JSON.parse(currency) : currency;
  
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (event) => setAnchorEl(event?.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleSelect = async (option) => {
    await actions.setCurrency(option);
    if(setData) setData(option);
    handleClose();
  }

  return (
    <>
    <button
      aria-owns={anchorEl ? 'simple-menu' : undefined}
      aria-haspopup="true"
      className="p-0 relative" 
      onClick={(e) => handleClick(e)}
    >
      <span className="text-gray-800 uppercase text-sm cursor-pointer text-center flex items-center">
        {!hideFlag && <span className="mr-2 mt-1">{current_currency?.flag_link}</span>} {current_currency?.isoCode && (current_currency?.isoCode).toUpperCase()} <KeyboardArrowDownIcon className="text-xs"/>
      </span>
    </button>
    <StyledMenu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{ left: '1rem' }}
    >
      {allCurrencies.map((option, index) => (
        <MenuItem
          key={index}
          className="text-sm text-gray-800"
          onClick={() => handleSelect(option)}
        >
          <span className="flex items-center">
            <span className="mt-1 mr-2">{option?.flag_link}</span> {(option?.isoCode).toUpperCase()}
          </span>
        </MenuItem>
      ))}
    </StyledMenu>
    </>
  )
}

function mapStateToProps(state, props) {
  const currency = state.currencies.get('currency');
  const currencies = state.currencies.get('currencies');
  return {
    currency,
    currencies,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCurrency);