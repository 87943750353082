import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  OutlinedInput,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  cssSelect: {
    width: 'calc((100% / 2) - 18px)',
    margin: '16px 8px 8px',
    background: `transparent !important`,
  },
  labelSelect: {
    position: 'absolute',
    top: '7px !important'
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

class SelectInput extends Component {
  bothInputProps = (which, value, error) => {
    const InputLabelProps = {
      classes: {
        root: (value && !error) ? 'cssLabel' : (((!value && error) || error) ? 'cssLabelError' : 'cssLabelSuccess'),
      },
      shrink: true,
    };
    const InputProps = {
      classes: {
        notchedOutline: (value && !error) ? 'cssInput' : (((!value && error) || error) ? 'cssInputError' : 'cssInputSuccess'),
      },
    };

    if (which === 'label') return InputLabelProps;
    if (which === 'input') return InputProps;
    return { InputLabelProps, InputProps };
  };

  render() {
    const {
      name,
      value,
      errors,
      onChange,
      label = '',
      placeholder = '',
      listItems = [],
      multiple = false,
      addClass = '',
      arrKey = '_id',
      arrLabel = 'name',
      labelClass = 'font-bold text-gray-800',
      disabled = false,
    } = this.props;

    return (
      <FormControl
        className={addClass}
        variant={'outlined'}
        disabled={disabled}
      >
        <InputLabel
          {...this.bothInputProps('label', value, errors)}
          htmlFor="select-multiple-checkbox"
        >
          <span className={labelClass}>{label}</span>
        </InputLabel>
        <Select
          displayEmpty={true}
          multiple={multiple}
          name={name}
          value={value}
          onChange={onChange}
          input={
            <OutlinedInput
              {...this.bothInputProps('input', value, errors)}
              labelWidth={100}
              id="select-multiple-checkbox"
            />
          }
          {...multiple && { renderValue: (selected) => selected.map(s => s[arrLabel] || s).join(', ') } }
          /*MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
              }
            }
          }}*/
        >
          <MenuItem disabled value={multiple ? [] : ''}>
            <label>{placeholder}</label>
          </MenuItem>
          {listItems.map((l, i) => (
            <MenuItem key={i} value={l[arrKey] || l}>
              {multiple && <Checkbox checked={value.some(v => v === (l[arrKey] || l))} />}
              {l[arrLabel] || l}
              {/*<ListItemText primary={l[arrLabel] || l} />*/}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

SelectInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectInput);
