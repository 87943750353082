import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  OutlinedInput,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  cssSelect: {
    width: 'calc((100% / 2) - 18px)',
    margin: '16px 8px 8px',
    background: `transparent !important`,
  },
  labelSelect: {
    position: 'absolute',
    top: '7px !important'
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

class SelectOutlined extends Component {
  bothInputProps = (which, value, error) => {
    const InputLabelProps = {
      classes: {
        root: (value && !error) ? 'cssLabel' : (((!value && error) || error) ? 'cssLabelError' : 'cssLabelSuccess'),
      },
      shrink: true,
    };
    const InputProps = {
      classes: {
        notchedOutline: (value && !error) ? 'cssInput' : (((!value && error) || error) ? 'cssInputError' : 'cssInputSuccess'),
      },
    };

    if (which === 'label') return InputLabelProps;
    if (which === 'input') return InputProps;
    return { InputLabelProps, InputProps };
  };

  render() {
    const {
      name,
      value,
      errors,
      onChange,
      label = '',
      listItems = [],
      multiple = false,
      addClass = '',
      arrKey = '_id',
      arrLabel = 'name',
      disabled = false,
    } = this.props;

    return (
      <FormControl
        className={addClass}
        variant={'outlined'}
      >
        <InputLabel
          {...this.bothInputProps('label', value, errors)}
          htmlFor="select-multiple-checkbox"
        >
          {label}
        </InputLabel>
        <Select
          disabled={disabled}
          multiple={multiple}
          name={name}
          value={Array.isArray(value) ? value.filter(v => v) : label}
          onChange={onChange}
          input={
            <OutlinedInput
              {...this.bothInputProps('input', value, errors)}
              labelWidth={100}
              id="select-multiple-checkbox"
            />}
          {...multiple && { renderValue: (selected) => selected.map(s => s[arrLabel] || s).join(', ') } }
          {...!multiple && { renderValue: (selected) => Array.isArray(selected) && selected.length > 0 ? selected[0][arrLabel] : selected } }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
              }
            }
          }}
        >
          {listItems.map((l, i) => (
            <MenuItem
              key={i}
              value={
                value && (Array.isArray(value) && value.length > 0)
                  ? (value.find(v => v && ((v[arrKey] && v[arrKey] === l[arrKey]) || (v === l))) || l)
                  : l
              }
            >
              {multiple && <Checkbox checked={value.some(v => v && ((v[arrKey] && v[arrKey] === l[arrKey]) || (v === l)))} />}
              <ListItemText primary={l[arrLabel] || l} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

SelectOutlined.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectOutlined);
