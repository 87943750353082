import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  close: {
    padding: theme.spacing(2) / 2,
  },
  snackbarStyleViaContentProps: {
    backgroundColor: '#FA9801',
    fontWeight: 'bold',
  },
});

function SnackBar(props) {
  const { classes } = props;
  return(
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={props.openAlert}
      autoHideDuration={6000}
      onClose={props.handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
        className: props.isWarning ? classes.snackbarStyleViaContentProps : ''
      }}
      message={<span id="message-id">{props.messageAlert}</span>}
      action={[
        !props.isWarning
          ? <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={props.handleClose}
          >
            <CloseIcon />
          </IconButton>
          : ''
      ]}
    />
  );
}

SnackBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

SnackBar.defaultProps = {
  isWarning: false,
};

export default withStyles(styles)(SnackBar);