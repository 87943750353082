import React, { Component } from 'react';

import {
  TextField,
  InputAdornment,
} from '@material-ui/core';

class TextInput extends Component {
  bothInputProps = (value, error, prefix = '') => ({
    InputLabelProps: {
      classes: {
        root: (value && !error) ? 'cssLabel' : (((!value && error) || error) ? 'cssLabelError' : 'cssLabelSuccess'),
      },
      shrink: true,
    },
    InputProps: {
      classes: {
        notchedOutline: (value && !error) ? 'cssInput' : (((!value && error) || error) ? 'cssInputError' : 'cssInputSuccess'),
      },
      ...prefix && { startAdornment: (<InputAdornment position="start">{prefix}</InputAdornment>) }
    },
  });

  render() {
    const {
      name,
      value,
      errors,
      onChange,
      onFocus,
      onEnter,
      error = true,
      required = true,
      label = '',
      prefix = '',
      addClass = '',
      type = 'text',
      helperText = '',
      placeholder = '',
      margin = 'normal',
      variant = 'outlined',
      multiline = false,
      rows = 2,
      labelClass = '',
      FormHelperTextProps = {},
      disabled = false,
      maxLength
    } = this.props;

    const labelInput = label ? <span className={labelClass}>{label}</span> : label;

    return (
      <TextField
        name={name}
        label={labelInput}
        placeholder={placeholder}
        type={type}
        helperText={helperText}
        variant={variant}
        className={addClass}
        {...this.bothInputProps(value, errors, prefix)}
        margin={margin}
        error={error}
        required={required}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        {...onEnter && {
          onKeyPress: (ev) => {
            if (ev.key === 'Enter') onEnter();
          }}
        }
        multiline={multiline}
        rows={rows}
        FormHelperTextProps={FormHelperTextProps}
        inputProps={maxLength ? { maxLength } : {}}
      />
    );
  }
}

export default TextInput;
